.page-search {
    padding: 12px;
    .search-header {
        display: flex;
        align-items: center;
        .icon {
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
        .input {
            flex: 1;
            height: 40px;
            line-height: 40px;
            span {
                font-size: 13px;
                color: #999999;
            }
        }
    }
    .content {
        .title {
            font-size: 13px;
            color: #999999;
            margin-bottom: 16px;
            margin-top: 24px;
        }
        .jz-tag-list {
            display: flex;
            flex-wrap: wrap;
            .tag {
                outline: 1px solid #EBEBEB;
                box-sizing: border-box;
                white-space: nowrap;
                display: inline-block;
                padding: 0 10px;
                height: 32px;
                line-height: 32px;
                border-radius: 6px;
                font-size: 11px;
                color: #343542;
                text-align: center;
                margin-right: 8px;
                margin-bottom: 12px;
                padding: 0 16px;
                border: none;
            }
            .active {
                background: #24c6b2;
                color: #fff;
                outline: none;
                border: none;
            }
        }
    }

}
