.container {
    background: #f7f7f7;
    width: 100%;
    overflow: hidden;
    .search-wrap {
        padding: 15px;
        background-color: #fff;
        border-bottom: 1px solid #EEEEF1;
        .logo-wrap {
            display: inline-block;
            span {
                display: flex;
                align-content: center;
                justify-content: center;
                // line-height: 1;
                height: 100%;
                text-align: center;
                white-space: nowrap;
                padding-right: 6px;
            }
        }
        .search {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            display: flex;
        }
        .input {
            width: 100%;
            height: 40px;
            line-height: 40px;
            --background: #F7F7F8;
        }
        input::placeholder {
            color: #999999;
        }
    }

    .content {
        // background-color: #fff;
        
    }
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }
}


