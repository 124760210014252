.tab-item-one-searchbar{
    padding: 11px 15px;
    .adm-search-bar-input-box{
        height: 34px;
        border-radius: 17px;
    }
}
.tab-item-one-search-item{
    width:100%;
    height:58px;
    font-size: 14px;
    &-high{
        color:#11BBA6;
    }
    &:nth-child(1){
        margin-top: 14px;
    }
    &>div:nth-child(2){
        font-weight: 400;
    font-size: 12px;
    color: #999999;
    margin-top: 14px;
    }
    border-bottom: 1px solid #E8E9EB;;
}
.tab-item-one-CascaderView{
    width: 100%;
}