.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
  overflow: hidden;
  .modal-content {
    position: relative;
    top: 30%;
  }

  .modal-close {
    position: absolute;
    top: 36px;
    right: 36px;
  }


  .close-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
.modal-wrap.active {
  display: none;
}

