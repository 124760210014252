
.reduction-wrap {
    display: flex;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #F2F2F2;
    padding: 12px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .reduction-bold {
        color: #353A40;
    }
    .reduction-normal {
        color: #9097A1;
    }
}
