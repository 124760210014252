.resume-field-border {
    border: 1px solid transparent;
    box-sizing: border-box;
}
.resume-field-focus {
    background-color: #E7F7F5 !important;
    border: 1px solid #11BBA6;
}
.resume-field-select-focus {
    background-color: #F4F4F4 !important;
    border: 1px solid #11BBA6;
}

.result-filed-border-none {
    border: none;
}
.result-filed-border-none * {
    border: none !important;
    outline: none !important;
}

.result-field-input-focus {
    background-color: rgba(0, 192, 145, 0.1) !important;
    outline: dashed 1px #00c091;
}

.result-field-input-error {
    overflow: visible !important;
    position: relative;
    outline: dashed 1px #EB5353;
    background-color: rgba(235,83,83,.1) !important;
}
.result-field-input-error::after {
    position: absolute;
    content: "";
    background-image: url("https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/jz-ppt-img/kjsdjkfhsk290.png");
    width: 14px;
    height: 14px;
    top: -7px;
    right: -7px;
    background-position: center;
    background-size: contain;
    z-index: 100;
}