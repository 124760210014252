.bottom-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1001;
  overflow: hidden;
  .bottom-modal-content {
    position: absolute;
    top: 30%;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    overflow-y: scroll;
  }
  .bottom-modal-children {

  }
  .bottom-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
    .close-icon {
      width: 18px;
      height: 18px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    z-index: 10;
  }
}



