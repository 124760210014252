.resume-1060 {
    .left-content {
        height: 100% !important;
    }
}

.resume-1050, .resume-1023 {
    .resume-l {
        height: 100% !important;
    }
}