.image-skeleton{
    width: 100%;
    height: 100%;
    background-color: #fff; 
}
.use-skeleton{
    padding:69px;
    background-color: #fff;
    height: 100%;
    width: 98%;

}