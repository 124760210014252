.dropdown-tag-list1 {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 16px;
    .tag {
        box-sizing: border-box;
        white-space: nowrap;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        border-radius: 4px;
        background: #fafafa;
        font-size: 14px;
        color: #343542;
        text-align: center;
        margin-right: 8px;
        margin-bottom: 8px;
        display: flex;
        font-weight: bold;
        .option-number{
            color:#8C8C8C;
            margin-left: 5px;
            font-weight: 400;
        }
        .active{
            color: #fff;
        }
    }
    .active {
        background: #24c6b2;
        color: #fff;
    }
    
}
.dropdown-title {
    display: flex;
    align-items: center;
    margin-right: 2px;
}
.adm-dropdown-item-highlight {
    color: #24C6B2;
}

