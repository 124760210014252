body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.dd-content {
    float: none !important;
}

/* .resume-family * {
    font-family: serif !important;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-popover-custom {
    background: transparent;
}

.ant-popover-custom .ant-popover-inner {
    padding: 0 !important;
    background: transparent;
    box-shadow: none;
}

.ant-popover-custom .ant-popover-arrow {
    visibility: hidden;
}

.ant-popover-custom-field-menu {

}

.ant-popover-custom-field-menu .ant-popover-inner {
    padding: 0 !important;
}

.ant-popover-custom-field-menu .ant-popover-arrow {
    visibility: hidden;
}

.field_box {
    /*word-break: break-all;*/
}

.field_box:hover {
    background-color: rgba(188,188,188,0.3);
}


.ant-custom-drawer {

}


.ant-custom-drawer .ant-drawer-header {
    display: none;
}

.ant-custom-drawer .ant-drawer-body {
    padding: 0;
}

.ant-btn-default.ant-custom-button-out {
    color: #11bba6;
    border-color: #11bba6;
}
.ant-tooltip.tooltipPointerEventsNone{
    pointer-events: none;
}
.theme-message-default .ant-message-notice-content{
    background: #EBF7EE !important;
    border: 1px solid #C5E8CE;
    font-size: 12px;
    color: #2FB454;
}
.theme-message-dark .ant-message-notice-content{
    background: #666666 !important;
    font-size: 12px;
    color: #FFFFFF;
}

.theme-message-dark .ant-message-notice-content svg{
    color: #999999;
}

span[contenteditable=true]{

}

dd, dl {
    margin: 0;
}

li {
    position: relative;
}

.ant-btn-default {
    box-shadow: none !important;
}
.module-content-list-container:hover {
    /* background-color: rgba(188, 188, 188, 0.3); */
    background-color: #F4F4F4;
}

.module-content-list-container-focus {
    background-color: transparent !important;
}

.module-content-list-container-ai-blur {
    outline: none !important;
}



.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}