.download {
    min-height: 100vh;
    padding: 26px 12px;
    padding-left: 20px;
    background: #fafafa;
    .table-header, .table-cell {
        color: #333;
        display: grid;
        grid-template-columns: 75px 1fr 65px;
        border-bottom: 1px solid #E3E3E3;
        padding: 8px 0;
        font-size: 14px;
    }
    .table-header-gpt, .table-cell-gpt {
        grid-template-columns: 75px 1fr 1fr 85px;
    }
    .table-cell {
        color: #666;
        align-items: center;
        .jz-resume-btn {
            font-size: 12px;
            background: #00BCA6;
            border-radius: 4px;
            width: 56px;
            height: 24px;
            line-height: 24px;
            color: #fff;
            text-align: center;
        }
    }
    .null {
        margin: 5px;
        text-align: center;
    }
}

.download-gpt {
    width: 500px;
    overflow-x: scroll;
}
