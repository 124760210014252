.my-page {
    background: #f7f7f7;
    min-height: calc(100vh - 130px);
    padding: 25px 15px;
    .user-info {
        display: flex;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 12px;
        padding: 20px 16px;
        justify-content: space-between;
        align-items: center;
    }

    .user-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 44px;
            height: 44px;
            margin-right: 25px;
        }
        .name {
            position: relative;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5f5f5f;
            margin-bottom: 8px;
        }
        .vip-icon {
            position: absolute;
            bottom: 4px;
            left: 14px;
            > div {
                height: 15px !important;
                width: 22px !important;
            }
        }
        .time {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c7c7c7;
        }
    }

    .user-vip {
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 12px;
    }

    .normal {
        background: #11BBA6;
        color: #fff;
    }

    .vip {
        background: #FEECBB;
        color: #9F823F;
    }

    .svip {
        background: #F7F7F7;
        color: #A9A9A9;
    }
    .un-login {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5F5F5F;
    }

    &-list {
        &-item {
            padding: 17px 14px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            .left {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 10px;
                    width: 21px;
                    height: 21px;
                    svg {
                        width: 21px;
                        height: 21px;
                    }
                }
                .title {
                    font-size: 15px;
                    flex: 1;
                }
            }
            .right .nav {
                width: 21px;
                height: 21px;
                svg {
                    width: 21px;
                    height: 21px;
                }
            }
            border-radius: 10px;
        }
    }

    
}

.my-page-modal {
    .modal-content {
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%);
    }
}

.user-quit-dialog{
    height:174px;
    .adm-dialog-title{
        margin-top: 10px;
        font-size: 16px;
    }
    .adm-dialog-content{
        font-size: 16px;
        margin-top: 21px;
        color: #999999 !important;

    }
    .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button{
        font-size: 16px;
        height: 53px;

    }
    .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button:nth-child(1){
        font-weight: bold;
        color: #000000;
    }
}
.user-quit-modal{
    height:174px;
    .adm-modal-header{
        .title{
            font-family: PingFangSC, PingFang SC;
            font-weight: bold !important;
            font-size: 16px !important;
            color: #000000 !important;
        }
       
    }
    .adm-modal-content{
        font-size: 16px;
        color: #999999 !important;
        display: flex;
    align-items: center;
    }
    .adm-modal-footer .adm-dialog-action-row > .adm-dialog-button{
        
    }
    .adm-modal-footer .adm-space-item> button{
        font-size: 16px;
        height: 36px;
        color: #fff;
        background: #11BBA6;
        border-radius: 6px;
    }
    .adm-modal-footer{
        padding: 20px;
    }
}